import { Box, styled } from "@mui/material";

const BarContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2rem 1rem",

  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    div: {
      gridColumn: "span 1",
      ":nth-last-child(2)": {
        gridColumn: "span 1",
      },
      ":last-child": {
        gridColumn: "span 2",
      },
    },
  },

  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    div: {
      gridColumn: "span 2",
      ":nth-last-child(2)": {
        gridColumn: "2 / span 2",
      },
      ":last-child": {
        gridColumn: "4 / span 2",
      },
    },
  },

  [theme.breakpoints.up("xl")]: {
    gridTemplateColumns: "repeat(5, 1fr)",
    maxWidth: "1700px",
    margin: "0 auto",
    div: {
      gridColumn: "span 1",
      ":nth-last-child(2)": {
        gridColumn: "span 1",
      },
      ":last-child": {
        gridColumn: "span 1",
      },
    },
  },
  a: {
    textDecoration: "none",
    cursor: "pointer",
  },
}));

const ItemContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "1.625rem",
  color: theme.palette.secondary.contrastText,
  whiteSpace: "nowrap",
  p: {
    margin: 0,
  },

  [theme.breakpoints.up("xxl")]: {
    fontSize: "1.375rem",
    margin: "1.5rem 0",
  },
}));

const TrustpilotContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",

  "& #trustbox": {
    display: "flex",
    alignItems: "center",
  },

  [theme.breakpoints.up("sm")]: {
    "& #trustbox iframe #tp-widget-rating": {
      fontSize: "22px !important",
    },
  },
}));

const TrustpilotAndFeaturedBar = () => (
  <Box
    sx={{
      padding: "1.25rem 0",
      backgroundColor: "white.main",
    }}
  >
    <BarContainer component="section">
      <ItemContainer>
        <a href="https://uk.trustpilot.com/review/mytutor.co.uk">
          <TrustpilotContainer>
            {/* <!-- TrustBox widget - Micro TrustScore --> */}
            <div
              id="trustbox"
              className="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="5419b732fbfb950b10de65e5"
              data-businessunit-id="591b1a030000ff0005a29184"
              data-style-height="22px"
              data-style-width="100%"
              data-theme="light"
            >
              <a
                href="https://uk.trustpilot.com/review/mytutor.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trustpilot
              </a>
            </div>
            {/* <!-- End TrustBox widget --> */}
          </TrustpilotContainer>
        </a>
      </ItemContainer>
      <ItemContainer sx={{ display: { xs: "none", sm: "flex" } }}>
        <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/testimonials/`}>
          <p>
            <span className="stars-reviews">
              426,535+&nbsp; &nbsp;5-star reviews
            </span>
          </p>
        </a>
      </ItemContainer>
      <ItemContainer>
        <p>1500 schools trust us</p>
      </ItemContainer>
      <ItemContainer sx={{ display: { xs: "none", sm: "flex" } }}>
        <p>30+ subjects available</p>
      </ItemContainer>
      <ItemContainer>
        <p>255,203+ students</p>
      </ItemContainer>
    </BarContainer>
  </Box>
);

export default TrustpilotAndFeaturedBar;
